import React from 'react'
import { Link } from 'react-router-dom'

const OrderSubmited = ({ formSubmitted }) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary OrderCompletedModal d-none"
          data-bs-toggle="modal"
          data-bs-target="#OrderCompletedModal"
        ></button>

        <div
          className="modal fade"
          id="OrderCompletedModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="OrderCompletedModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center mt-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="56"
                    fill="currentColor"
                    className="bi bi-check2-all"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                  </svg>
                </div>
                <p className="heading-m pt-5 text-capitalize ">
                  Order Purchased Successfully...
                </p>
                <p className="text-center pt-3 text-capitalize ">
                  Your Esim Details Has Been Send to you by Email
                </p>
              </div>
              <div
                className={`modal-footer position-relative OrderCompletedModalbtn ${
                  formSubmitted ? "OrderCompletedModalbtntimer" : ""
                }`}
              >
                <Link
                  to="https://etijwaal.com/"
                  type="button"
                  className="btn primary-btn mx-auto btn-sm "
                >
                  Close
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default OrderSubmited
