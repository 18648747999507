import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Loader from '../loaders/Loader';
import axios from 'axios';
import { app_url } from '../../config';
const PaypalModal = ({ paypalinfo }) => {
    const [paypalinfostate, setpaypalinfostate] = useState(null)
    const [transitionid, settransitionid] = useState('')
    const [loading, setloading] = useState(false)
    useEffect(() => {
        setpaypalinfostate(paypalinfo)
        settransitionid(paypalinfo?.data.order[0].systemOrderId)
    }, [paypalinfo])


    const handleApprove = async (data, actions) => {
        const order = await actions.order.capture();
        if (order) {
            console.log('Payment was approved!', order, order.purchase_units[0].payments.captures[0].id);
            const customId = data.orderID;
            // console.log('Custom ID:', customId);
            setloading(true)
            axios.post(`${app_url}/api/payment/confirmOrder`, { systemOrderId: transitionid, transactionId: order.purchase_units[0].payments.captures[0].id })
                .then(response => {
                    if (response.data.success) {
                        document.querySelector('.OrderCompletedModal').click()
                        setloading(false)
                    }
                    else {
                        console.log(response.data.message)
                        setloading(false)
                    }

                })
                .catch(error => {
                    // toast.error(error)
                    console.log(error);
                    setloading(false)
                });
        }
    };
    const handleError = (err) => {
        console.log('Payment error:', err);
    };
    return (
      <>
        {loading ? (
          <div className="loader-2">
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-primary paypal-modal d-none "
              data-bs-toggle="modal"
              data-bs-target="#PaypalModal"
            >
              Launch static backdrop modal
            </button>

            <div
              className="modal fade"
              id="PaypalModal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="PaypalModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  {paypalinfostate ? (
                    <div className="modal-body py-5">
                      <PayPalScriptProvider
                        options={{
                          "client-id": paypalinfostate?.data.publishableKey,
                        }}
                      >
                        <PayPalButtons
                          createOrder={(data, actions) => {
                            // Define the order creation logic here
                            return actions.order.create({
                              purchase_units: [
                                {
                                  amount: {
                                    currency_code:
                                      paypalinfostate?.data.order[0].currency.toUpperCase(),
                                    value: paypalinfostate?.data.order[0].price,
                                  },
                                  custom_id:
                                    paypalinfostate?.data.order[0]
                                      .systemOrderId,
                                },
                              ],
                            });
                          }}
                          onApprove={handleApprove}
                          onError={handleError}
                        />
                      </PayPalScriptProvider>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
}

export default PaypalModal
