import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../assets/images/etijwaallogo.jpg'

const Notfound = () => {
    return (
        <>
            <section className="page_404">
                <div className="container">
                    <div className="row justify-content-center " >

                            <div className="col-sm-10 col-sm-offset-1  text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>


                                </div>

                                <img src={logo} alt="" classNameName='img404' />
                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        Look's like you're lost
                                    </h3>
                                    <p>the page you are looking for not avaible!</p>

                                    <button to="" onClick={() => window.history.back()} className="btn primary-btn">Go to Home</button>
                                </div>
                            </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Notfound
