import React from 'react'

const Emailsend = ({ changeemail }) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary emailsend d-none"
          data-bs-toggle="modal"
          data-bs-target="#emailverified"
        ></button>

        <div
          className="modal fade"
          id="emailverified"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="emailverifiedLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p-md-4">
                <i className="bi bi-envelope-check"></i>
                <p className="fs-4 fw-bolder ">
                  We've sent you a verification email
                </p>
                <p className="para text-black mt-4 ">
                  You'll need to confirm you have access to your email before
                  you can start using your Roadpass.
                  <br /> <br />
                  This email may take a few minutes to arrive. Check the inbox
                  for <b>no-reply@etijwaal.com</b>.
                  <br /> <br />
                  <b>Note:</b> Check your spam folder in case the email was
                  incorrectly identified
                  <br /> <br />
                  You will have 10 minutes to verify your account, after that
                  you will need to request a new verification link.
                </p>
              </div>
              <div className="modal-footer px-md-4 border-0 pt-0 mb-3 justify-content-start">
                <button
                  type="button"
                  onClick={changeemail}
                  data-bs-dismiss="modal"
                  className="btn primary-btn-border mt-2"
                >
                  {" "}
                  didn't get an email
                </button>
                <button
                  type="button"
                  className="btn primary-btn-border ms-md-2 mt-2"
                  data-bs-dismiss="modal"
                >
                  {" "}
                  I've got an email
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Emailsend
