import { Button } from 'bootstrap'
import React from 'react'
import { Link } from 'react-router-dom'

const PaymentFailed = () => {
    return (
      <section className="mx-auto d-flex failed-payment align-items-center justify-content-center">
        <div>
          <div
            className="alert  alert-fail alert-danger d-flex bg-light gap-2"
            role="alert"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              fill="currentColor"
              className="bi bi-exclamation"
              viewBox="0 0 16 16"
            >
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0z" />
            </svg>
            <div>
              <h6 className="ooops">Oops! Something went wrong.</h6>
              <p className="m-0 oooo">
                While trying to reserve money from your account
              </p>
            </div>
          </div>
          <button className="bg-tertiary btn-alert w-100 border-0 rounded py-2">
            <Link to="https://etijwaal.com/" className="text-dark">
              Try again
            </Link>
          </button>
        </div>
      </section>
    );
}

export default PaymentFailed