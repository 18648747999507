import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserdetailForm from './components/UserdetailForm'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/custom.css'
import { Toaster, toast } from "react-hot-toast";
import Notfound from './components/Notfound';
import PaymentSuccess from './components/payment/PaymentSuccess';
import PaymentFailed from './components/payment/PaymentFailed';
function App() {
  return (
    <>
     
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            fontSize: "16px",
            padding: "10px 20px",
          },
        }}
      />
      <BrowserRouter basename='/complete-order'>
        <Routes>
          <Route path="/:id" element={<UserdetailForm />}></Route>
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/failed" element={<PaymentFailed />} />
          <Route path='/' element={<Notfound />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
