import { useLocation } from "react-router-dom"




const PaymentSuccess = () => {
    const location = window.location.search;
    const urlParams = new URLSearchParams(location);
     const name = urlParams.get('name')
     const amount = urlParams.get('amount')
     const tapId = urlParams.get('tap_id')
     const date = urlParams.get('date')
     console.log('date',date)
    console.log('tapId', tapId)
    return (
        <section style={{ height: 800 }} className="d-flex align-items-center justify-content-cente">
            <div style={{ maxWidth: 500 }} className="card shadow-lg border-0 text-center mx-auto">
                <div className="card-body">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#f57c21" className="bi bi-check2-square" viewBox="0 0 16 16">
                        <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                        <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                    </svg>
                    <h4 className="card-title">Payment Recieved</h4>
                    {/* <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                    <div className="mb-2">
                        <p className="card-text mb-3">Hi {name},</p>
                        <p className="card-text">Your transaction was successfull</p>
                    </div>
                    <div className="pb-4 text-start">
                        <h6 className="small">Payment Details: </h6>
                        <p className="card-text small mb-0">Amount: ${amount}</p>
                        <p className="card-text small">Account: *******</p>
                    </div>
                </div>
                <div className="card-footer">
                    <h6 className="small">Transaction reference: <br /> {tapId}</h6>
                    <p className="small">Order Date: {new Date(date)?.toLocaleString()}</p>
                </div>
            </div>
        </section>
    )

}

export default PaymentSuccess;